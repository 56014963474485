import './App.css';
import { Routes1 } from './Routes.js';
function App() 
{
  return (
    <Routes1/>
  );
}

export default App;
