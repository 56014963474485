import React, { useEffect } from "react";
import { Home } from "./pages/Homepage.js";
import { NotFoundPage } from "./pages/404.js";

// import Pdf from "./assets/img/artwork.pdf";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Routes,
    useNavigate,
} from "react-router-dom";
export const Bebranded = () => {
    const navigate = useNavigate();
  
    // Redirect to the specified URL
    useEffect(() => {
      // Redirect to the specified URL
      //window.location.href = 'https://www.bebranded.com.au/';
    }, []); // Empty dependency array ensures useEffect runs only once
  };
export const Routes1 = () => {
    // const navigate = useNavigate();
    const isAuthenticated = async (event) => {
        if (!localStorage.getItem('token')) {
            // navigate("/signin");   .
            // navigate("/signin");   
        }
    }
    return (
        <Router>
            <Routes>
                <Route path="/:token" element={<Home />} />

                               {/* {/* <Route path="*" element={<NotFoundPage />} /> */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Router>   
    );
};
