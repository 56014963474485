import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
  useEffect(() => {
    window.location.href = 'https://frontend.bebranded.com.au/';
  }, [])
  
  return (

      <div id="error-page">
        <div className="content">
          <h2 className="header" data-text="404">
            404
          </h2>
          <h4 data-text="Opps! Page not found">
            Opps! Page not found
          </h4>
          <p>
            Sorry, the page you're looking for doesn't exist.
          </p>
          <div className="trend_bottom_btn">
            <Link to="/">Return Home</Link>
          </div>
        </div>
    </div>
  );
};

