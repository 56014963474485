import axios from "axios";
import { toast } from "react-toastify";
const API_BASE_BACKEND = process.env.REACT_APP_API_URL;

export const getartwork = async (token) => {
    try {
        if (token) {
        const response = await axios.post(
            API_BASE_BACKEND + "frontend/api/order/getartWork",{
                ArtWorkToken: token.token  
            },
            {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
            }
            );
            return response;
        }
    } catch (error) {
        return error;
    }
};

export const artwork_post = async (artworkid, status, OrderID, orderDetailsId) => {
    try {
        // const token = localStorage.getItem("token");

        const response = await axios.post(
            API_BASE_BACKEND + `frontend/api/order/artworkapprovereject`,
            {
                OrderID: OrderID,
                OrderDetailsID: orderDetailsId,
                OrderArtworkAttachmentID: artworkid,
                Approve: status,
            },
            // {
            //     headers: {
            //         Authorization: `${token}`,
            //     },
            // }
        );
        return response;
    } catch (error) {
        return error;
    } 
};
