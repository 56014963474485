// HomePage.js
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import {
    getartwork,
    artwork_post
} from "../service/api.js";
import { toast } from "react-toastify";
export const Home = () => {
    const token = useParams();
    const [artall, setartall] = useState();
    const [proof, setproof] = useState(false);
    const [pdfindex, setpdfindex] = useState(0);
    const getartwork_click = async () => {
        const res = await getartwork(token);
        console.log(res.data.Message);
        
        setartall(res.data.AllData);
    }
    const getartwork_click2 = async () => {
        const res = await getartwork(token);
        console.log(res.data.Message);
        if (res.data.Message == "EXPIRED") {
            setproof(true);
        } else {
            setproof(false);
        }
    }
    const handlepdf = (a) => {
        setpdfindex(a)
        // alert(a);
    }
    const artwork_api = async (artworkid, status) => {
        try {
            console.log(artall);
            const res = await artwork_post(artworkid, status, artall.OrderData, artall.OrderDetails);
            if (res.status == 200) {
                    console.log(res);
                setartall((prv) => {
                    const updatedOrderArtworkAttachment = [...prv.OrderArtworkAttachment];
                    updatedOrderArtworkAttachment[pdfindex] = {
                        ...updatedOrderArtworkAttachment[pdfindex],
                        Approve: status
                    };
    
                    return {
                        ...prv,
                        OrderArtworkAttachment: updatedOrderArtworkAttachment
                    };
                });
                handleExceptionSuccessMessages("Response submit");
            }
        } catch (error) {
            handleExceptionError("Response not submited");
            console.error("Error fetching data:", error);
        }
    }
    useEffect(()=> {
        getartwork_click();
        getartwork_click2();
    },[]) 

    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
  
    function handleExceptionError(res) {
      // alert("sf");
      setExceptionError(ExceptionError => [
        ...ExceptionError,
        { id: Date.now(), message: res },
      ]);
    }
  
    function handleExceptionSuccessMessages(res) {
      setSuccessMessages(successMessages => [
        ...successMessages,
        { id: Date.now(), message: res },
      ]);
    }
  
    function clearErrors(id) {
      setExceptionError(prevMessages =>
        prevMessages.filter(msg => msg.id !== id)
      );
    }
    function clearSuccess(id) {
      setSuccessMessages(prevMessages =>
        prevMessages.filter(msg => msg.id !== id)
      );
    }
  

    return (
        <>
            {proof==false ? (
                <>
        <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
        <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
            <header>
                <div className="container">
                    <div className="header_part_1">
                        <img src="/Logo.png"/>
                    </div>
                    <div className="header_part_2">
                        <h2>Order Id :- #{artall?.OrderData}</h2>
                    </div>
                </div>
            </header>
            <section className="bebranded_artwork_section">
                <div className="container">
                    <div className="bebranded_artwork_part">
                        <div className="bebranded_artwork_part_list">
                            <h3>Artworks</h3>
                            <div className="artwork_data_wrapper">
                                        {artall?.OrderArtworkAttachment &&  artall?.OrderArtworkAttachment.map((item, index) => (
                                    <div className={`${pdfindex == index && "active "}" artwork_listing "`} onClick={() => handlepdf(index)}>
                                        <span>{item?.Attachment.split('_')[1]}</span>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="bebranded_artwork_part bebranded_artwork_part2">
                        <div className="bebranded_artwork_part2_img">
                            {/* <img src="/artbanner.jpg"/> */}
                        <iframe src={`https:///api.bebranded.com.au/${artall?.OrderArtworkAttachment[pdfindex]?.Attachment}`} style={{width:'100%', height: '500px'}}></iframe>
                        </div>
                        <div className="button_div_art">
                            {artall?.OrderArtworkAttachment[pdfindex]?.Approve == 2 && (                            
                            <><button onClick={() => artwork_api(artall?.OrderArtworkAttachment[pdfindex].OrderArtworkAttachmentID, 1)}>Approve</button><button onClick={() => artwork_api(artall?.OrderArtworkAttachment[pdfindex].OrderArtworkAttachmentID, 0)}>Reject</button></>
                            )}
                        </div>
                            {artall?.OrderArtworkAttachment[pdfindex]?.Approve == 1 && (
                            <div className="button_div_art" style={{justifyContent:'center', marginTop:'-40px'}}>
                                <><p style={{fontFamily:'poppins', color:'green', textAlign:'center'}}>Artwork approved</p></></div>
                            )}
                            {artall?.OrderArtworkAttachment[pdfindex]?.Approve == 0 && (
                            <div className="button_div_art" style={{justifyContent:'center', marginTop:'-40px'}}>
                                <><p style={{fontFamily:'poppins', color:'red', textAlign:'center'}}>Artwork rejected</p></></div>
                            )}
                    </div>
                </div>
            </section>
            </>
            ) : (
                <>
                <div style={{height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <h1 style={{ fontFamily: 'poppins', color: '#01aef0', textAlign: 'center', fontSize:'40px' }}>Link Expired</h1>
                </div>
                </>
            )}
        </>
    );
};
